import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import Navbar from './Navbar';
import ReCAPTCHA from 'react-google-recaptcha';
import headerImage from './images/header.jpg';

function Contact() {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!captchaVerified) {
      alert('Please complete the CAPTCHA verification');
      return;
    }
    emailjs
      .sendForm('service_kkadc71', 'template_19w5bfn', e.target, 'j1QF5huCh3hoBa7Ei')
      .then(() => alert('Message sent successfully!'))
      .catch(() => alert('An error occurred. Please try again.'));
    e.target.reset();
  };

  const onCaptchaChange = (value) => setCaptchaVerified(true);

  return (
    <>
      <div className="contact-page">
        <Navbar />
        <img src={headerImage} alt="Header" className="contact-background-image" />
        <div className='contact-container'>
          <h1>Contact</h1>
          <div className="contact-notes">
            <p>If you prefer, you can also use the mail icon in the top right corner to send an email directly.</p>
            <p>Both options will reach the same email address.</p>
          </div>
          <form className="contact-form" onSubmit={sendEmail}>
            <input
              type="text"
              name="user_name"
              placeholder="Your Name"
              value={formData.user_name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="user_email"
              placeholder="Your Email"
              value={formData.user_email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>

            <ReCAPTCHA
              sitekey="6LcDH18qAAAAAPtVhv8PvhmYPUFyj_oOzdHD1p0X"
              onChange={onCaptchaChange}
            />

            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
